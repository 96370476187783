import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { HeaderBannerContactPg } from "../components/Contact-Page/header-banner-contact"
import { ContactBody } from "../components/Contact-Page/contact-body"


const ContactPage = (props) => {
    return (
        <Layout>
            <Seo
                title="Contact Kaylar Construction: Get in Touch for Kitchen Remodeling, Bathroom Remodeling, Siding, Windows & Doors, Decks & Patios, and Interior Design Services"
                description="Ready to get started on your dream project? Contact us today for expert general contracting services in kitchen remodeling, bathroom remodeling, siding, windows & doors, decks & patios, and interior design. Let's turn your vision into reality!"
                location={props.location.pathname}
                keywords={['Kaylar Construction']}
            />
            <div>
                <HeaderBannerContactPg />
                <ContactBody />
            </div>
        </Layout>
    )
}
export default ContactPage