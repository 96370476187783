import React from 'react'
import Lock from "../../icons/lock"

const ContactForm = () => {
    return (
        <form
            name="Contact Page"
            method="POST"
            data-netlify="true"
            action="/success"
        >
            <input type="hidden" name="form-name" value="Contact Page" />
            <div className="flex flex-wrap">
                <div className="w-full pr-4 pl-4 lg:pr-2 lg:pl-2 form-group">
                    <input name="name" type="text" placeholder="Name" />
                </div>
                <div className="w-full pr-4 pl-4 lg:pr-2 lg:pl-2 form-group">
                    <input name="phone" type="text" placeholder="Phone Number" />
                </div>
                <div className="w-full pr-4 pl-4 lg:pr-2 lg:pl-2 form-group">
                    <input name="zipcode" type="text" placeholder="Zip Code *" required />
                </div>
                <div className="w-full pr-4 pl-4 lg:pr-2 lg:pl-2 form-group">
                    <input name="email" type="email" placeholder="Email *" required />
                </div>
                <div className="w-full pr-4 pl-4 lg:pr-2 lg:pl-2 form-group">
                    <textarea name="message" id="message" cols="30" flex-wraps="4" placeholder="Question / Message *" required />
                </div>
                <div className="pt-5 pb-7 flex text-sm items-center text-gray-400 justify-center text-center">
                    <Lock />
                    <span className="pl-2">
                        We Value Your Privacy
                    </span>
                </div>
            </div>
            <div className="w-full pr-4 pl-4 lg:pr-2 lg:pl-2 pt-4">
                <button type="submit" className='relative h-10 py-2 px-5 transition-colors rounded-lg border focus:shadow-outline hover:shadow-lg font-semibold transition duration-300 ease-in-out text-sm text-white border-darkblue bg-darkblue hover:bg-lightblue hover:text-white' title='Submit Message' alt='Submit Message - Kaylar Construction'>Send Message</button>
            </div>
        </form>
    )
}
export default ContactForm