import React, { Component } from "react"
import ContactForm from "./contact-form"

export class ContactBody extends Component {
    render() {
        return (
            <section className="contact py-12 md:py-16">
                <div className="container mx-auto">
                    <div className="flex flex-wrap justify-center lg:space-x-32">
                        <div className="lg:w-2/5 pr-4 pl-4">
                            <h5>Contact Information</h5>
                            <p className="mb-30">
                                At Kaylar Construction, Naz, Vitaliy, Vladimir, and our dedicated team are committed to providing a personalized, hands-on experience for every customer. Upon reaching out, you can expect us to contact you
                                through your preferred method to schedule a free estimate appointment at your convenience. During this meeting, we'll discuss your desired services in detail, allowing us to generate a tailored estimate for your project. We value strong
                                customer relationships and strive to ensure a smooth and personalized experience from start to finish.
                            </p>
                            <div className="contact-link">
                                <div className="contact-link-icon"><span className="norc-phone"></span></div>
                                <div className="contact-link-content">
                                    <div className="contact-link-title">Call us</div>
                                    <a className="contact-link-text" href="tel:+19162450073" title="Call Kaylar Construction" alt="Call Kaylar Construction"><span className="hover:text-lightblue transition duration-300 ease-in-out">(916)-245-0073</span></a>
                                </div>
                            </div>
                            <div className="contact-link">
                                <div className="contact-link-icon"><span className="norc-mail"></span></div>
                                <div className="contact-link-content">
                                    <div className="contact-link-title">Send us an email</div>
                                    <a className="contact-link-text" href="mailto:kci@kaylarconstruction.com" title="Email Kaylar Construction" alt="Email Kaylar Construction"><span className="hover:text-lightblue transition duration-300 ease-in-out">kci@kaylarconstruction.com</span></a>
                                </div>
                            </div>
                            <div className="contact-link">
                                <div className="contact-link-icon"><span className="norc-square-pin"></span></div>
                                <div className="contact-link-content">
                                    <div className="contact-link-title">Visit our office</div>
                                    <a className="contact-link-text" href=""
                                        target="_blank" rel="noreferrer" title="Visit Kaylar Construction on Google" alt="Visit Kaylar Construction on Google"><span className="hover:text-lightblue transition duration-300 ease-in-out">Sacramento, CA</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-2/5 pr-4 pl-10 lg:pl-0 lg:mx-1/5 pt-12 lg:pt-0">
                            <div className="form-wrap">
                                <div className="form-box">
                                    <h5>Contact Us</h5>
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}